import axios from '@/common/axios'
import qs from 'qs'

export function addSurgicalElectrodeTestRecord (data) {
  return axios({
    method: 'post',
    url: '/quality/surgicalRecord/add',
    data: qs.stringify(data)
  })
}

export function deleteSurgicalElectrodeTestRecord (id) {
  return axios({
    method: 'delete',
    url: '/quality/surgicalRecord/delete/' + id
  })
}

export function updateSurgicalElectrodeTestRecord (data) {
  return axios({
    method: 'put',
    url: '/quality/surgicalRecord/update',
    data: qs.stringify(data)
  })
}

export function selectSurgicalElectrodeTestRecordInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/surgicalRecord/info/' + id
  })
}

export function selectSurgicalElectrodeTestRecordList (query) {
  return axios({
    method: 'get',
    url: '/quality/surgicalRecord/list',
    params: query
  })
}

export function reviewSurgicalElectrodeTestRecord (data) {
  return axios({
    method: 'put',
    url: '/quality/surgicalRecord/updateReview',
    data: qs.stringify(data)
  })
}
