<template>
  <div id="surgicalElectrodeTestRecord">
    <el-dialog
      :title="surgicalElectrodeTestRecordFormTitle"
      width="1200px"
      :visible.sync="surgicalElectrodeTestRecordDialogVisible"
      :close-on-click-modal="false"
      @close="surgicalElectrodeTestRecordDialogClose"
    >
      <el-form
        ref="surgicalElectrodeTestRecordFormRef"
        :model="surgicalElectrodeTestRecordForm"
        :rules="surgicalElectrodeTestRecordFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="产品名称" prop="productName">
              <el-input
                v-model="surgicalElectrodeTestRecordForm.productName"
                readonly
                :disabled="surgicalElectrodeTestRecordFormTitle !== '新增一次性使用手术电极过程检验记录'
                  && surgicalElectrodeTestRecordFormTitle !== '修改一次性使用手术电极过程检验记录'
                  && surgicalElectrodeTestRecordFormTitle !== '一次性使用手术电极过程检验记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="规格型号" prop="spec">
              <el-input
                v-model="surgicalElectrodeTestRecordForm.spec"
                placeholder="请输入规格型号"
                clearable
                :disabled="surgicalElectrodeTestRecordFormTitle !== '新增一次性使用手术电极过程检验记录'
                  && surgicalElectrodeTestRecordFormTitle !== '修改一次性使用手术电极过程检验记录'
                  && surgicalElectrodeTestRecordFormTitle !== '一次性使用手术电极过程检验记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="批号" prop="batchNo">
              <el-input
                v-model="surgicalElectrodeTestRecordForm.batchNo"
                placeholder="请输入批号"
                clearable
                :disabled="surgicalElectrodeTestRecordFormTitle !== '新增一次性使用手术电极过程检验记录'
                  && surgicalElectrodeTestRecordFormTitle !== '修改一次性使用手术电极过程检验记录'
                  && surgicalElectrodeTestRecordFormTitle !== '一次性使用手术电极过程检验记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="计划生产数量" prop="plannedQuantity">
              <el-input
                v-model="surgicalElectrodeTestRecordForm.plannedQuantity"
                placeholder="请输入计划生产数量"
                clearable
                :disabled="surgicalElectrodeTestRecordFormTitle !== '新增一次性使用手术电极过程检验记录'
                  && surgicalElectrodeTestRecordFormTitle !== '修改一次性使用手术电极过程检验记录'
                  && surgicalElectrodeTestRecordFormTitle !== '一次性使用手术电极过程检验记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="检验日期" prop="inspectionDate">
              <el-date-picker
                v-model="surgicalElectrodeTestRecordForm.inspectionDate"
                placeholder="请选择检验日期"
                value-format="yyyy-MM-dd"
                :disabled="surgicalElectrodeTestRecordFormTitle !== '新增一次性使用手术电极过程检验记录'
                  && surgicalElectrodeTestRecordFormTitle !== '修改一次性使用手术电极过程检验记录'
                  && surgicalElectrodeTestRecordFormTitle !== '一次性使用手术电极过程检验记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="检验类别" prop="identifyCategory">
              <el-input
                v-model="surgicalElectrodeTestRecordForm.identifyCategory"
                readonly
                :disabled="surgicalElectrodeTestRecordFormTitle !== '新增一次性使用手术电极过程检验记录'
                  && surgicalElectrodeTestRecordFormTitle !== '修改一次性使用手术电极过程检验记录'
                  && surgicalElectrodeTestRecordFormTitle !== '一次性使用手术电极过程检验记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检验依据" prop="testBased">
              <el-input
                v-model="surgicalElectrodeTestRecordForm.testBased"
                readonly
                :disabled="surgicalElectrodeTestRecordFormTitle !== '新增一次性使用手术电极过程检验记录'
                  && surgicalElectrodeTestRecordFormTitle !== '修改一次性使用手术电极过程检验记录'
                  && surgicalElectrodeTestRecordFormTitle !== '一次性使用手术电极过程检验记录详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="recordDetailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="process"
              title="工序"
              width="150"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="standard"
              title="标准要求"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="inspectionQuantity"
              title="抽检数量"
              width="100"
              :edit-render="{name: '$input', props: {type: 'number', clearable: true}}"
            />
            <vxe-table-colgroup title="抽检检验结果" header-align="center">
              <vxe-table-column
                field="qualifiedQuantity"
                title="合格品数"
                width="100"
                :edit-render="{name: '$input', props: {type: 'number', clearable: true}}"
              />
              <vxe-table-column
                field="unqualifiedQuantity"
                title="不合格品数"
                width="120"
                :edit-render="{name: '$input', props: {type: 'number', clearable: true}}"
              />
            </vxe-table-colgroup>
            <vxe-table-column
              field="measure"
              title="不合格品处理措施初步评审"
              width="120"
              :edit-render="{
                name: '$select',
                props: {clearable: true},
                options: [
                  { value: '返工', label: '返工' },
                  { value: '返修', label: '返修' },
                  { value: '报废', label: '报废' },
                  { value: '其他', label: '其他' }
                ]
              }"
            />
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="检验记录" prop="testRecord">
              <el-input
                v-model="surgicalElectrodeTestRecordForm.testRecord"
                placeholder="请输入检验记录"
                type="textarea"
                :rows="3"
                :disabled="surgicalElectrodeTestRecordFormTitle !== '新增一次性使用手术电极过程检验记录'
                  && surgicalElectrodeTestRecordFormTitle !== '修改一次性使用手术电极过程检验记录'
                  && surgicalElectrodeTestRecordFormTitle !== '一次性使用手术电极过程检验记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="surgicalElectrodeTestRecordForm.remarks"
                placeholder="请输入备注"
                type="textarea"
                :rows="3"
                :disabled="surgicalElectrodeTestRecordFormTitle !== '新增一次性使用手术电极过程检验记录'
                  && surgicalElectrodeTestRecordFormTitle !== '修改一次性使用手术电极过程检验记录'
                  && surgicalElectrodeTestRecordFormTitle !== '一次性使用手术电极过程检验记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="复核结果" prop="reviewResult">
              <el-radio-group
                v-model="surgicalElectrodeTestRecordForm.reviewResult"
                :disabled="surgicalElectrodeTestRecordFormTitle !== '复核一次性使用手术电极过程检验记录'
                  && surgicalElectrodeTestRecordFormTitle !== '一次性使用手术电极过程检验记录详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="surgicalElectrodeTestRecordDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="surgicalElectrodeTestRecordFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="批号">
        <el-input v-model="searchForm.batchNo" placeholder="请输入批号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['OPERATION_PROCESS_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="surgicalElectrodeTestRecordPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="spec" label="规格型号" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="plannedQuantity" label="计划生产数量" />
      <el-table-column label="检验日期">
        <template slot-scope="scope">
          <span v-if="scope.row.inspectionDate">{{ scope.row.inspectionDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="identifyCategory" label="检验类别" />
      <el-table-column prop="testBased" label="检验依据" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="testRecord" label="检验记录" />
      <el-table-column prop="inspector" label="检验人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.inspectionDate1">{{ scope.row.inspectionDate1.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate">{{ scope.row.reviewDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="复核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewResult === 1">通过</span>
          <span v-if="scope.row.reviewResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['OPERATION_PROCESS_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['OPERATION_PROCESS_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['OPERATION_PROCESS_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            复核
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="surgicalElectrodeTestRecordPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addSurgicalElectrodeTestRecord,
  deleteSurgicalElectrodeTestRecord,
  updateSurgicalElectrodeTestRecord,
  selectSurgicalElectrodeTestRecordInfo,
  selectSurgicalElectrodeTestRecordList,
  reviewSurgicalElectrodeTestRecord
} from '@/api/quality/surgicalElectrodeTestRecord'

export default {
  data () {
    return {
      surgicalElectrodeTestRecordDialogVisible: false,
      surgicalElectrodeTestRecordFormTitle: '',
      surgicalElectrodeTestRecordForm: {
        id: '',
        productName: '一次性使用手术电极',
        spec: '',
        batchNo: '',
        plannedQuantity: '',
        inspectionDate: '',
        identifyCategory: '过程检验',
        testBased: '一次性使用手术电极过程检验操作规程',
        testRecord: '',
        remarks: '',
        reviewResult: '',
        status: '',
        recordDetailJson: '',
        taskId: ''
      },
      surgicalElectrodeTestRecordFormRules: {
        batchNo: [{ required: true, message: '批号不能为空', trigger: ['blur', 'change']}]
      },
      surgicalElectrodeTestRecordPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        batchNo: ''
      },
      recordDetailList: [
        { process: '清洗', standard: '表面无灰尘、油污', measure: '' },
        { process: '焊接（关键）', standard: 'a)焊接线的位置不能焊错、漏焊、虚焊\nb)焊点应光滑、大小适中\nc)焊接好的内护套线不应有破损\nd)电缆线与线路连接处应能承受40N拉力，历时15s，不得拉脱', measure: '' },
        { process: '组装热合（关键）', standard: 'a）黄按钮在cut键，蓝按钮在coag键\nb）线路板在上壳中的定位正确，电线应卡在上壳的卡槽内\nc）上下壳塑料件粘结应牢固、无裂缝\nd）刀头应插到位，方向应正确，并检查插拔力9-15N', measure: '' },
        { process: '整件检验', standard: 'a）外观：电刀笔刀头部位无明显机械损伤、变形、锋棱、毛刺等缺陷；手术电极柄部表面色泽均匀，无飞边、裂缝、划痕等缺陷。功能指示字样应清晰；刀头位置方面正确\nb）功能检查：手指按黄色（cut）按钮时，验压器上应红色指示灯亮；按蓝色（coag）按钮时，验压器上绿色灯亮\nc）导通性：用万用表R*1Ω档测量电刀柄刀头与线路板的导通电阻应小于10Ω\nd）其他：刀头与柄部连接牢固，无松动，手控开关装配牢固，工作可靠，无卡滞，按钮手感、声音轻重基本一致', measure: '' },
        { process: '装PE袋', standard: '袋内应无异物且无破损现象', measure: '' },
        { process: '封小包装（特殊）', standard: 'a）纸塑袋中应无杂质，尤其不得有头发丝、昆虫、纸屑等\nb）打码情况：\n①　外袋上的型号、生产日期、批号、失效期等打码应正确\n②　外袋打码不得漏打\n③　封口机温度应达到规定温度（300℃±10℃）\n④　封口宽度必须≥8毫米\nc）封口应牢固，封口处至少应能承受30N拉力', measure: '' }
      ]
    }
  },
  created () {
    selectSurgicalElectrodeTestRecordList(this.searchForm).then(res => {
      this.surgicalElectrodeTestRecordPage = res
    })
  },
  methods: {
    surgicalElectrodeTestRecordDialogClose () {
      this.$refs.surgicalElectrodeTestRecordFormRef.resetFields()
      this.recordDetailList = [
        { process: '清洗', standard: '表面无灰尘、油污', measure: '' },
        { process: '焊接（关键）', standard: 'a)焊接线的位置不能焊错、漏焊、虚焊\nb)焊点应光滑、大小适中\nc)焊接好的内护套线不应有破损\nd)电缆线与线路连接处应能承受40N拉力，历时15s，不得拉脱', measure: '' },
        { process: '组装热合（关键）', standard: 'a）黄按钮在cut键，蓝按钮在coag键\nb）线路板在上壳中的定位正确，电线应卡在上壳的卡槽内\nc）上下壳塑料件粘结应牢固、无裂缝\nd）刀头应插到位，方向应正确，并检查插拔力9-15N', measure: '' },
        { process: '整件检验', standard: 'a）外观：电刀笔刀头部位无明显机械损伤、变形、锋棱、毛刺等缺陷；手术电极柄部表面色泽均匀，无飞边、裂缝、划痕等缺陷。功能指示字样应清晰；刀头位置方面正确\nb）功能检查：手指按黄色（cut）按钮时，验压器上应红色指示灯亮；按蓝色（coag）按钮时，验压器上绿色灯亮\nc）导通性：用万用表R*1Ω档测量电刀柄刀头与线路板的导通电阻应小于10Ω\nd）其他：刀头与柄部连接牢固，无松动，手控开关装配牢固，工作可靠，无卡滞，按钮手感、声音轻重基本一致', measure: '' },
        { process: '装PE袋', standard: '袋内应无异物且无破损现象', measure: '' },
        { process: '封小包装（特殊）', standard: 'a）纸塑袋中应无杂质，尤其不得有头发丝、昆虫、纸屑等\nb）打码情况：\n①　外袋上的型号、生产日期、批号、失效期等打码应正确\n②　外袋打码不得漏打\n③　封口机温度应达到规定温度（300℃±10℃）\n④　封口宽度必须≥8毫米\nc）封口应牢固，封口处至少应能承受30N拉力', measure: '' }
      ]
    },
    surgicalElectrodeTestRecordFormSubmit () {
      if (this.surgicalElectrodeTestRecordFormTitle === '一次性使用手术电极过程检验记录详情') {
        this.surgicalElectrodeTestRecordDialogVisible = false
        return
      }
      this.$refs.surgicalElectrodeTestRecordFormRef.validate(async valid => {
        if (valid) {
          if (this.surgicalElectrodeTestRecordFormTitle === '新增一次性使用手术电极过程检验记录') {
            this.surgicalElectrodeTestRecordForm.id = ''
            this.surgicalElectrodeTestRecordForm.status = 1
            this.surgicalElectrodeTestRecordForm.recordDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await addSurgicalElectrodeTestRecord(this.surgicalElectrodeTestRecordForm)
          } else if (this.surgicalElectrodeTestRecordFormTitle === '修改一次性使用手术电极过程检验记录') {
            this.surgicalElectrodeTestRecordForm.recordDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await updateSurgicalElectrodeTestRecord(this.surgicalElectrodeTestRecordForm)
          } else if (this.surgicalElectrodeTestRecordFormTitle === '复核一次性使用手术电极过程检验记录') {
            this.surgicalElectrodeTestRecordForm.status = 2
            await reviewSurgicalElectrodeTestRecord(this.surgicalElectrodeTestRecordForm)
          }
          this.surgicalElectrodeTestRecordPage = await selectSurgicalElectrodeTestRecordList(this.searchForm)
          this.surgicalElectrodeTestRecordDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.surgicalElectrodeTestRecordFormTitle = '新增一次性使用手术电极过程检验记录'
      this.surgicalElectrodeTestRecordDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteSurgicalElectrodeTestRecord(row.id)
        if (this.surgicalElectrodeTestRecordPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.surgicalElectrodeTestRecordPage = await selectSurgicalElectrodeTestRecordList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.surgicalElectrodeTestRecordFormTitle = '修改一次性使用手术电极过程检验记录'
      this.surgicalElectrodeTestRecordDialogVisible = true
      this.selectSurgicalElectrodeTestRecordInfoById(row.id)
    },
    handleReview (index, row) {
      this.surgicalElectrodeTestRecordFormTitle = '复核一次性使用手术电极过程检验记录'
      this.surgicalElectrodeTestRecordDialogVisible = true
      this.selectSurgicalElectrodeTestRecordInfoById(row.id)
    },
    handleInfo (index, row) {
      this.surgicalElectrodeTestRecordFormTitle = '一次性使用手术电极过程检验记录详情'
      this.surgicalElectrodeTestRecordDialogVisible = true
      this.selectSurgicalElectrodeTestRecordInfoById(row.id)
    },
    selectSurgicalElectrodeTestRecordInfoById (id) {
      selectSurgicalElectrodeTestRecordInfo(id).then(res => {
        this.surgicalElectrodeTestRecordForm.id = res.id
        this.surgicalElectrodeTestRecordForm.productName = res.productName
        this.surgicalElectrodeTestRecordForm.spec = res.spec
        this.surgicalElectrodeTestRecordForm.batchNo = res.batchNo
        this.surgicalElectrodeTestRecordForm.plannedQuantity = res.plannedQuantity
        this.surgicalElectrodeTestRecordForm.inspectionDate = res.inspectionDate
        this.surgicalElectrodeTestRecordForm.identifyCategory = res.identifyCategory
        this.surgicalElectrodeTestRecordForm.testBased = res.testBased
        this.surgicalElectrodeTestRecordForm.testRecord = res.testRecord
        this.surgicalElectrodeTestRecordForm.remarks = res.remarks
        this.surgicalElectrodeTestRecordForm.reviewResult = res.reviewResult
        this.surgicalElectrodeTestRecordForm.status = res.status
        this.surgicalElectrodeTestRecordForm.taskId = res.taskId
        this.recordDetailList = res.recordDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectSurgicalElectrodeTestRecordList(this.searchForm).then(res => {
        this.surgicalElectrodeTestRecordPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectSurgicalElectrodeTestRecordList(this.searchForm).then(res => {
        this.surgicalElectrodeTestRecordPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectSurgicalElectrodeTestRecordList(this.searchForm).then(res => {
        this.surgicalElectrodeTestRecordPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['OPERATION_PROCESS_REVIEW']) && row.status === 1) {
        return 'review'
      }
      return ''
    }
  }
}
</script>

<style>
</style>
